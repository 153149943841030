import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputAdornment } from "@mui/material";
import { Controller } from "react-hook-form";
import { MuiTelInput } from "mui-tel-input";

const FormPhoneNumber = ({
  control,
  defaultValue,
  label,
  controllerName,
  type,
  size,
}) => {
  // const startAdornmentStyle = {
  //   borderLeft: "2px solid blue", // Adjust the border style as needed
  //   height: "100%", // Set the height to match the input height
  //   marginRight: "8px", // Adjust the margin as needed
  // };
  return (
    <FormControl
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: "30px",
      }}
    >
      <Controller
        name={controllerName}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <MuiTelInput
            forceCallingCode
            defaultCountry={process.env.REACT_APP_COUNTRY}
            label={label}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            variant="outlined"
            size="small"
            disableDropdown={true}
            sx={{
              ".MuiTypography-root": {
                color: "white",
              },
            }}
            MenuProps={{
              sx: {
                ".MuiTelInput-MenuItem": {
                  color: "white",
                },
                ".MuiTypography-root": {
                  color: "white",
                },
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};

FormPhoneNumber.propTypes = {
  label: PropTypes.string,
  controllerName: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
};

FormPhoneNumber.defaultProps = {
  label: "",
  controllerName: "",
  type: "text",
  size: "medium",
};

export default FormPhoneNumber;
