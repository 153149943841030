import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  createTheme,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import AccordionComponent from "../components/common/Accordion";
import { useTranslation } from "react-i18next";
import React from "react";

const Terms = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box backgroundColor={theme.palette.background.secondaryPaper}>
      {mobileView && (
        <>
          <Grid container justify="center" pt={1} pb={5}>
            <Grid item xs={4}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <Typography
                textAlign="center"
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                {t("t&c.title")}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Container>
        {/* Terms */}
        <Box py={!mobileView && 4} mb={mobileView && 4}>
          <Typography
              variant="h6"
              component="h6"
              fontWeight={800}
              color={theme.palette.primary.main}
          >
            {t("t&c.title")}
          </Typography>
          <Typography variant="body1" component="div" color="white"
                      dangerouslySetInnerHTML={{__html: t("t&c.desc1")}}>
          </Typography>
          <br/>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.desc2")}
          </Typography>
          <br/>
          {/* Start of 1*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            1 {t("t&c.1.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            1.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.1.1.1")}
          </Typography>
          <br/>
          {/* End of 1*/}

          {/* Start of 2*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            2 {t("t&c.2.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            2.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.2.2.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            2.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.2.2.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            2.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.2.2.3")}
          </Typography>
          <br/>
          {/* End of 2*/}

          {/* Start of 3*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            3 {t("t&c.3.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            3.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            3.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.2")}
          </Typography>
          <br/>
          {/* End of 3*/}

          {/* Start of 4*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            4 {t("t&c.4.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            4.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.4.4.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            4.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.4.4.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            4.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.4.4.3")}
          </Typography>
          <br/>
          {/* End of 4*/}

          {/* Start of 5*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            5 {t("t&c.5.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.firstdesc")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.4")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.5")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.6")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.7
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.7")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.8
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.8")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.9
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.9")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.10
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.10")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.11
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.11")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.12
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.12")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.13
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.13")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.14
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.14")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.15
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.15")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.16
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.16")}
          </Typography>
          <br/>
          {/* End of 5*/}

          {/* Start of 6*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            6 {t("t&c.4.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            6.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            6.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            6.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            6.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.4")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            6.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.5")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            6.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.6")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            6.7
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.7")}
          </Typography>
          <br/>
          {/* End of 6*/}

          {/* Start of 7*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            7 {t("t&c.7.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.4")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.5")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.6")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.7
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.7")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.7.1 {t("t&c.7.sub.7.7.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.7.2 {t("t&c.7.sub.7.7.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.7.3 {t("t&c.7.sub.7.7.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.8
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.8")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.9
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.9")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.10
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.10")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.11
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.11")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.12
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.12")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.13
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.13")}
          </Typography>
          <br/>
          {/* End of 7*/}

          {/* Start of 8*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            8 {t("t&c.8.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            8.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            8.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            8.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            8.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.4")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            8.4.1 {t("t&c.8.sub.8.4.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            8.4.2 {t("t&c.8.sub.8.4.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            8.4.3 {t("t&c.8.sub.8.4.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            8.4.4 {t("t&c.8.sub.8.4.4")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            8.4.5 {t("t&c.8.sub.8.4.5")}
          </Typography>
          <br/>
          {/* End of 8*/}

          {/* Start of 9*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            9 {t("t&c.9.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            9.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            9.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            9.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            9.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.4")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            9.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.5")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            9.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.6")}
          </Typography>
          <br/>
          {/* End of 9*/}

          {/* Start of 10*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            10 {t("t&c.10.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            10.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.10.10.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            10.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.10.10.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            10.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.10.10.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            10.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.10.10.4")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            10.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.10.10.5")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            10.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.10.10.6")}
          </Typography>
          <br/>
          {/* End of 10*/}

          {/* Start of 11*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            11 {t("t&c.11.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            11.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            11.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            11.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            11.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.4")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            11.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.5")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            11.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.6")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            11.7
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.7")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            11.8
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.8")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            11.9
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.9")}
          </Typography>
          <br/>
          {/* End of 11*/}

          {/* Start of 12*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            12 {t("t&c.12.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            12.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.1")}
          </Typography>
          <br/>
          {/* End of 12*/}

          {/* Start of 13*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            13 {t("t&c.13.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            13.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.13.13.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            13.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.13.13.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            13.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.13.13.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            13.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.13.13.4")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            13.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.13.13.5")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            13.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.13.13.6")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            13.7
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.13.13.7")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            13.8
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.13.13.8")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            13.9
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.13.13.9")}
          </Typography>
          <br/>
          {/* End of 13*/}

          {/* Start of 14*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            14 {t("t&c.14.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            14.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.14.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            14.1.1 {t("t&c.14.sub.14.1.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            14.1.2 {t("t&c.14.sub.14.1.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            14.1.3 {t("t&c.14.sub.14.1.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            14.1.4 {t("t&c.14.sub.14.1.4")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            14.1.5 {t("t&c.14.sub.14.1.5")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            14.1.6 {t("t&c.14.sub.14.1.6")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            14.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.14.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            14.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.14.3")}
          </Typography>
          <br/>
          {/* End of 14*/}

          {/* Start of 15*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            15 {t("t&c.15.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            15.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.15.15.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            15.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.15.15.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            15.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.15.15.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            15.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.15.15.4")}
          </Typography>
          <br/>
          {/* End of 15*/}

          {/* Start of 16*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            16 {t("t&c.16.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            16.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.16.16.1")}
          </Typography>
          <br/>
          {/* End of 16*/}

          {/* Start of 17*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            17 {t("t&c.17.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            17.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.17.17.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            17.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.17.17.2")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            17.2.1 {t("t&c.17.sub.17.2.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            17.2.2 {t("t&c.17.sub.17.2.3")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            17.2.2 {t("t&c.17.sub.17.2.3")}
          </Typography>
          <br/>
          {/* End of 17*/}

          {/* Start of 18*/}
          <Typography
              variant="body1"
              component="div"
              color="white"
              fontWeight={800}
          >
            18 {t("t&c.18.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            18.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.18.18.1")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            18.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.18.18.2")}
          </Typography>
          <br/>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.18.firstdesc")}
          </Typography>
          <br/>
          {/* End of 18*/}
        </Box>
      </Container>
    </Box>
  );
};

export default Terms;
