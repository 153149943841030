import {
  Box,
  Container,
  Tab,
  Tabs,
  Grid, IconButton,
  Typography, useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import React from "react";
import { useState } from "react";
import AccordionComponent from "../components/common/Accordion";

const Faq = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const PATH = process.env.PUBLIC_URL;
  const [tab, setTab] = useState(0);

  const renderFAQ1Answer = () => (
      <>
        <Typography variant="body2" component="div" color="white"
                    dangerouslySetInnerHTML={{__html: t("faq.1.firstdesc")}}>
        </Typography>
        <br/>
        <Typography variant="body2" component="div" color="white"
                    dangerouslySetInnerHTML={{__html: t("faq.1.step.title")}}>
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.1")}}/>
            <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.2")}}/>
            <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.3")}}/>
            <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.4")}}/>
            <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.5")}}/>
            <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.6")}}/>
            <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.7")}}/>
          </ol>
        </Typography>
      </>
  );

  const renderFAQ2Answer = () => (
      <>
        <Typography variant="body2" color="white"
                    dangerouslySetInnerHTML={{__html: t("faq.2.firstdesc")}}>
        </Typography>
        <br/>
        <Typography variant="body2" color="white"
                    dangerouslySetInnerHTML={{__html: t("faq.2.seconddesc")}}>
        </Typography>
        <br/>
        <Typography variant="body2" color="white"
                    dangerouslySetInnerHTML={{__html: t("faq.2.thirddesc")}}>
        </Typography>
      </>
  );

  const renderFAQ3Answer = () => (
      <>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.3.firstdesc")}}>
                    </Typography>
                    <br/>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.3.step.title")}}>
                    </Typography>
                    <Typography variant="body2" component="div">
                      <ol>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.1")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.2")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.3")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.4")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.5")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.6")}}/>
                      </ol>
                    </Typography>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.3.seconddesc")}}>
                    </Typography>
      </>
  );

  const renderFAQ4Answer = () => (
      <>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.4.firstdesc")}}>
                    </Typography>
                    <br/>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.4.step.title")}}>
                    </Typography>
                    <Typography variant="body2" component="div">
                      <ol>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.4.step.1")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.4.step.2")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.4.step.3")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.4.step.4")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.4.step.5")}}/>
                      </ol>
                    </Typography>
      </>
  );

  const renderFAQ5Answer = () => (
      <>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.5.firstdesc")}}>
                    </Typography>
                    <br/>
      </>
  );

  const renderFAQ6Answer = () => (
      <>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.6.firstdesc")}}>
                    </Typography>
      </>
  );

  const renderFAQ7Answer = () => (
      <>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.7.firstdesc")}}>
                    </Typography>
      </>
  );

  const renderFAQ8Answer = () => (
      <>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.8.firstdesc")}}>
                    </Typography>
                    <br/>
                    <Typography variant="body2" component="div">
                      <ol>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.8.step.1")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.8.step.2")}}/>
                      </ol>
                    </Typography>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.8.seconddesc")}}>
                    </Typography>
      </>
  );

  const renderFAQ9Answer = () => (
      <>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.9.firstdesc")}}>
                    </Typography>
                    <br/>
                    <Typography variant="body2" component="div">
                      <ol>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.9.step.1")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.9.step.2")}}/>
                        <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.9.step.3")}}/>
                      </ol>
                    </Typography>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.9.seconddesc")}}>
                    </Typography>
      </>
  );

  const renderFAQ10Answer = () => (
      <>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.10.firstdesc")}}>
                    </Typography>
      </>
  );

  const renderFAQ11Answer = () => (
      <>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.11.firstdesc")}}>
                    </Typography>
      </>
  );

  const renderFAQ12Answer = () => (
      <>
                    <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.12.firstdesc")}}>
                    </Typography>
      </>
  );

  const renderFAQ13Answer = () => (
      <>
        <Typography variant="body2" component="div" color="white"
                                dangerouslySetInnerHTML={{__html: t("faq.13.firstdesc")}}>
                    </Typography>
      </>
  );

  const faqs = [
    {
      title: t("faq.title"),
      faq: [
        {
          question: t("faq.1.title"),
          answer: renderFAQ1Answer(),
        },
        {
          question: t("faq.2.title"),
          answer: renderFAQ2Answer(),
        },
        {
          question: t("faq.3.title"),
          answer: renderFAQ3Answer(),
        },
        {
          question: t("faq.4.title"),
          answer: renderFAQ4Answer(),
        },
        {
          question: t("faq.5.title"),
          answer: renderFAQ5Answer(),
        },
        {
          question: t("faq.6.title"),
          answer: renderFAQ6Answer(),
        },
        {
          question: t("faq.7.title"),
          answer: renderFAQ7Answer(),
        },
        {
          question: t("faq.8.title"),
          answer: renderFAQ8Answer(),
        },
        {
          question: t("faq.9.title"),
          answer: renderFAQ9Answer(),
        },
        {
          question: t("faq.10.title"),
          answer: renderFAQ10Answer(),
        },
        {
          question: t("faq.11.title"),
          answer: renderFAQ11Answer(),
        },
        {
          question: t("faq.12.title"),
          answer: renderFAQ12Answer(),
        },
        {
          question: t("faq.13.title"),
          answer: renderFAQ13Answer(),
        },
      ],
    },
  ];

  const tabChangeHandler = (e, value) => {
    setTab(value);
  };

  return (

      // <>
      //   <Container>
      //     <Box my={4}>
      //       <Grid container spacing={4}>
      //         <Grid item md={4}>
      //           <Tabs
      //               orientation="vertical"
      //               variant="scrollable"
      //               value={tab}
      //               onChange={tabChangeHandler}
      //               aria-label="FAQ question categories"
      //               visibleScrollbar={false}
      //           >
      //             {faqs.map((a, i) => (
      //                 <Tab label={a.title} value={i} />
      //             ))}
      //           </Tabs>
      //         </Grid>
      //         <Grid item md={8}>
      //           <Box>
      //             <Typography variant="h5" color={theme.palette.primary.main}>
      //               {faqs[tab].title}
      //             </Typography>
      //             <br />
      //             {faqs[tab].faq.map((a, i) => (
      //                 <AccordionComponent title={a.question}>
      //                   {a.answer}
      //                 </AccordionComponent>
      //             ))}
      //           </Box>
      //         </Grid>
      //       </Grid>
      //     </Box>
      //   </Container>
      // </>

      <Box>
        {mobileView && (
            <>
              <Grid container justify="center" pt={1} pb={5}>
                <Grid item xs={4}>
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack />
                  </IconButton>
                </Grid>
                <Grid item xs={4} alignSelf="center">
                  <Typography
                      textAlign="center"
                      fontWeight={600}
                      color={theme.palette.primary.main}
                  >
                    {t("faq.title")}
                  </Typography>
                </Grid>
              </Grid>
            </>
        )}
        <>
          <Container mt={mobileView ? 0 : null}>
            <Box py={!mobileView && 4}>
              {/* FAQ */}
              <Typography
                  variant="h6"
                  component="h6"
                  fontWeight={800}
                  color={theme.palette.primary.main}
                  dangerouslySetInnerHTML={{__html: t("faq.title")}}
              >
              </Typography>
              {/* Start of Question 1 */}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.1.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.1.firstdesc")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.1.step.title")}}>
              </Typography>
              <Typography variant="body2" component="div">
                <ol>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.1")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.2")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.3")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.4")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.5")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.6")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.1.step.7")}}/>
                </ol>
              </Typography>
              {/* End of Question 1*/}
              {/* Start of Question 2*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.2.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.2.firstdesc")}}>
              </Typography>
              <br/>
              <Typography variant="body2" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.2.seconddesc")}}>
              </Typography>
              <br/>
              <Typography variant="body2" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.2.thirddesc")}}>
              </Typography>
              <br/>
              {/* End of Question 2*/}
              {/* Start of Question 3*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.3.title")}}>
              </Typography>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.3.firstdesc")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.3.step.title")}}>
              </Typography>
              <Typography variant="body2" component="div">
                <ol>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.1")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.2")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.3")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.4")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.5")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.3.step.6")}}/>
                </ol>
              </Typography>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.3.seconddesc")}}>
              </Typography>
              <br/>
              {/* End of Question 3*/}
              {/* Start of Question 4*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.4.title")}}>
              </Typography>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.4.firstdesc")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.4.step.title")}}>
              </Typography>
              <Typography variant="body2" component="div">
                <ol>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.4.step.1")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.4.step.2")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.4.step.3")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.4.step.4")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.4.step.5")}}/>
                </ol>
              </Typography>
              <br/>
              {/* End of Question 4*/}
              {/* Start of Question 5*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.5.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.5.firstdesc")}}>
              </Typography>
              <br/>
              {/* End of Question 5*/}
              {/* Start of Question 6*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.6.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.6.firstdesc")}}>
              </Typography>
              <br/>
              {/* End of Question 6*/}
              {/* Start of Question 7*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.7.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.7.firstdesc")}}>
              </Typography>
              <br/>
              {/* End of Question 7*/}
              {/* Start of Question 8*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.8.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.8.firstdesc")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div">
                <ol>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.8.step.1")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.8.step.2")}}/>
                </ol>
              </Typography>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.8.seconddesc")}}>
              </Typography>
              <br/>
              {/* End of Question 8*/}
              {/* Start of Question 9*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.9.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.9.firstdesc")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div">
                <ol>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.9.step.1")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.9.step.2")}}/>
                  <li style={{margin: "16px", color: "white"}} dangerouslySetInnerHTML={{__html: t("faq.9.step.3")}}/>
                </ol>
              </Typography>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.9.seconddesc")}}>
              </Typography>
              <br/>
              {/* End of Question 9*/}
              {/* Start of Question 10*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.10.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.10.firstdesc")}}>
              </Typography>
              <br/>
              {/* End of Question 10*/}
              {/* Start of Question 11*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.11.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.11.firstdesc")}}>
              </Typography>
              <br/>
              {/* End of Question 11*/}
              {/* Start of Question 12*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.12.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.12.firstdesc")}}>
              </Typography>
              <br/>
              {/* End of Question 12*/}
              {/* Start of Question 13*/}
              <Typography variant="body1" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.13.title")}}>
              </Typography>
              <br/>
              <Typography variant="body2" component="div" color="white"
                          dangerouslySetInnerHTML={{__html: t("faq.13.firstdesc")}}>
              </Typography>
              <br/>
              {/* End of Question 13*/}


            </Box>
          </Container>
        </>
      </Box>
  );
};

export default Faq;
