import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  Grid,
  useMediaQuery,
  Tabs,
  Tab,
  darken,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextField from "../form/FormTextField";
import FormDatePicker from "../form/FormDatePicker";
import FormFileInput from "../form/FormFileInput";
import { setAlert } from "../../features/slices/alertSlice";
import Image from "../common/Image";
import { useTranslation } from "react-i18next";
import CopiedTooltip from "../common/CopiedTooltip";
import {useNavigate} from "react-router-dom";
import * as Constant from "../../features/constant";
import CircularProgress from '@material-ui/core/CircularProgress';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
};

const Deposit = ({ depositDetails, useDepositMutation }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabValue, setTabValue] = useState(0);

  const [amount, setAmount] = useState('');
  const [reamount, setReamount] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState(5);
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const bankDetails = depositDetails?.banks?.filter(
    (detail) => detail?.bankCategory === 'BANK' || !detail?.bankCategory
  ) || [];

  const eWalletDetails = depositDetails?.banks?.filter(
    (detail) => detail?.bankCategory === 'EWALLET'
  ) || [];

  useEffect(() => {
    const savedTimerData = sessionStorage.getItem('timerData');
    if (savedTimerData) {
      const { endTime } = JSON.parse(savedTimerData);
      const now = new Date().getTime();
      const timeLeft = Math.round((endTime - now) / 1000);

      if (timeLeft > 0) {
        setTimer(timeLeft);
        setIsDisabled(true);
      } else {
        sessionStorage.removeItem('timerData');
      }
    }
  }, []);

  useEffect(() => {
    if (isDisabled && timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer - 1 <= 0) {
            sessionStorage.removeItem('timerData');
            setIsDisabled(false);
          }
          return prevTimer - 1;
        });
      }, 1000);

      // Calculate future end time and save in sessionStorage
      const endTime = new Date().getTime() + timer * 1000;
      sessionStorage.setItem('timerData', JSON.stringify({ endTime }));

      return () => clearInterval(intervalId);
    }
  }, [timer, isDisabled]);

  useEffect(() => {
    if (shouldNavigate) {
      navigate(Constant.getPath('/'));
    }
  }, [shouldNavigate, navigate]);

  const handleClick = () => {
    if (!isDisabled) {
      setTimer(5);
      setIsDisabled(true);
    }
  };
  const [selectedQuickpay, setSelectedQuickpay] = useState(
    depositDetails?.paymentGateways[
      Object?.keys(depositDetails?.paymentGateways)[0]
    ]?.paymentGateway
  );
  const [allQuickpayBank, setAllQuickpayBank] = useState(
    depositDetails?.paymentGateways[
      Object?.keys(depositDetails?.paymentGateways)[0]
    ]?.banks || []
  );
  const [selectedQuickpayBank, setSelectedQuickpayBank] = useState(
    depositDetails?.paymentGateways[
      Object?.keys(depositDetails?.paymentGateways)[0]
    ]?.banks[0]
  );

  const [selectedQrCodeMethod, setSelectedQrCodeMethod] = useState("bank");
  const { t } = useTranslation();


  const depositType = [];
  if (bankDetails && Object.keys(bankDetails).length > 0) {
    depositType.push({ label: t("walletcard.depositpage.title"), category: "bank" });
  }
  
  if (eWalletDetails && Object.keys(eWalletDetails).length > 0) {
    depositType.push({ label: t("walletcard.depositpage.ewallet"), category: "ewallet" });
  }
  
  if (depositDetails?.paymentGateways && Object.keys(depositDetails.paymentGateways).length > 0) {
    depositType.push({ label: t("walletcard.depositpage.quickpay.title"), category: "paymentgateway" });
  }

  const depositSchema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t("validation.amountonly"))
      .min(30, t("validation.minamount"))
      .required(t("validation.amountisrequired")),
    receipt: yup.mixed().test("file", t("validation.receipt"), (value) => {
      if (value?.length > 0) {
        return true;
      }
      return false;
    }),
    // ignore for now
    // promotion: yup.string().required("Please select a promotion."),
  });

  const quickpaySchema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t("validation.amountonly"))
      .min(30, t("validation.minamount"))
      .required(t("validation.amountisrequired")),
  });

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(depositSchema),
  });

  const {
    control: qpControl,
    handleSubmit: handleQpSubmit,
    setValue: setQpValue,
    reset: resetQp,
  } = useForm({
    resolver: yupResolver(quickpaySchema),
  });

  const [selectedCategory, setSelectedCategory] = useState(() => {
    // Dynamically set the default to the first category in depositType
    const initialDepositType = [];
    if (bankDetails && Object.keys(bankDetails).length > 0) {
      initialDepositType.push({ label: t("walletcard.depositpage.title"), category: "bank" });
    }
    if (eWalletDetails && Object.keys(eWalletDetails).length > 0) {
      initialDepositType.push({ label: t("walletcard.depositpage.ewallet"), category: "ewallet" });
    }
    if (depositDetails?.paymentGateways && Object.keys(depositDetails.paymentGateways).length > 0) {
      initialDepositType.push({ label: t("walletcard.depositpage.quickpay.title"), category: "paymentgateway" });
    }
  
    return initialDepositType.length > 0 ? initialDepositType[0].category : "bank"; 
  });
  const [selectedBank, setSelectedBank] = useState(bankDetails[0]);
  const [selectedEwalletBank, setSelectedEwalletBank] = useState(eWalletDetails[0]);

  const [deposit, { data, isLoading, isError, isSuccess, error }] =
    useDepositMutation();

  const handleTabChange = (event, newValue) => {
    const selectedCategory = depositType[newValue]?.category;
    setTabValue(newValue);
    setSelectedCategory(selectedCategory);
    console.log("selected cat", selectedCategory);
    setReamount(0);
    setSelectedQrCodeMethod('bank');
  };

  const submitDeposit = async (data) => {
    const formData = new FormData();
    //const date = new Date(data.date).toISOString().split("T");
    formData.append("amount", data.amount);
    // formData.append("receiptDateTime", date[0] + ";" + date[1]);
    formData.append("receipt", data.receipt[0]);
    formData.append(
      "bankCode",
      selectedBank?.bankCode || bankDetails[0].bankCode
    );
    await deposit({ deposit: formData, paymentGateway: "" }).unwrap();
    if (!isDisabled) {
      setTimer(5);
      setIsDisabled(true);
    }
    setShouldNavigate(true);
  };

  const submitQuickpay = async (data) => {
    const formData = new FormData();
    formData.append("bankCode", selectedQuickpayBank?.bankCode);
    formData.append("amount", data?.amount);
    const res = await deposit({
      deposit: formData,
      paymentGateway: selectedQuickpay,
    }).unwrap();

    if (res) {
      // window.open(res.result.paymentUrl, "_blank");
      window.location.href = res.result.paymentUrl;
    }
    resetQp();
    if (!isDisabled) {
      setTimer(5);
      setIsDisabled(true);
    }
    navigate(Constant.getPath('/'));
  };

  const submitQrCode = async (data) => {
    const formData = new FormData();
    //const date = new Date(data.date).toISOString().split("T");
    formData.append("amount", data.amount);
    // formData.append("receiptDateTime", date[0] + ";" + date[1]);
    formData.append("receipt", data.receipt[0]);
    formData.append(
      "bankCode",
      selectedEwalletBank?.bankCode || eWalletDetails[0].bankCode
    );
    formData.append("depositMethod", selectedQrCodeMethod);
    await deposit({ deposit: formData, paymentGateway: "" }).unwrap();
    if (!isDisabled) {
      setTimer(5);
      setIsDisabled(true);
    }
    setShouldNavigate(true);
  };

  useEffect(() => {
    
    if (amount !== '') {
      const processingFee = 
      selectedCategory === 'bank'
        ? selectedBank?.processingFee 
        : selectedCategory === 'paymentgateway'
          ? selectedQuickpayBank?.processingFee 
          : selectedCategory === 'ewallet'
            ? selectedEwalletBank?.processingFee 
            : undefined;
    const reamountValue = parseFloat(amount || 0) + parseFloat(processingFee);

    setReamount(isNaN(reamountValue) ? '' : reamountValue.toFixed(2));
    }
  }, [amount, depositDetails]);


  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  useEffect(() => {
    // Define deposit success listener
    window.depositSuccessListener = window.depositSuccessListener || window;

    // Define deposit submit listener
    window.depositSubmitListener = window.depositSubmitListener || window;

    if (!isLoading && isSuccess) {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("walletcard.depositpage.successmessage"),
          variant: "success",
        })
      );
      reset();
      // window.fbq('trackCustom', 'CompleteDeposit');
      // window.bge('event', 'ec_purchase', {configId: '904500226561349376'});

      const depositSuccessMessage = { event: 'af_deposit_success' };
      window.depositSuccessListener.postMessage(JSON.stringify(depositSuccessMessage));

      const depositSubmitMessage = { event: 'af_deposit_submit', value: {amount}, currency: 'KRW' };
      window.depositSubmitListener.postMessage(JSON.stringify(depositSubmitMessage));

    }
    if (!isLoading && isError) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error?.data?.message,
          variant: "error",
        })
      );
      reset();
    }
  }, [isLoading, isSuccess, isError]);
const depositTypeWithIndex = depositType.map((type, index) => ({
  ...type,
  index, // Dynamically assign the correct index
}));
  return (
    <Box px={1} >
      <Typography fontWeight="bold" color="white" sx={{
          marginBottom: "8px"
        }}>
        {t("walletcard.depositpage.paymentMethod")}
      </Typography>
      
      <Tabs
        onChange={handleTabChange}
/*        sx={{
          marginLeft: "15px"
        }}*/
      >
        {depositType.map((type, index) => (
          <Tab
          icon={
            <Image
              width="40px"
              height="40px"
              src={process.env.REACT_APP_PROFILE + `/payment_method_` + index + `.png`}
            />}
            key={index}
            label={type.label}
            sx={{
              color: theme.palette.primary.main,
              border:
                index === tabValue && `2px solid ${theme.palette.primary.main}` ||
                index !== tabValue && `1px solid ${theme.palette.button.faded.main}`,
              fontWeight: 600,
              borderRadius: "5px",
              padding: "5px 5px",
              margin: "0  5px",
              background: "rgba(255,255,255,0.25)",
              flex: 0,
              fontSize: "10px",
            }}

          />
        ))}
      </Tabs>
      {/* <TabPanel value={tabValue} index={2}> */}
      {console.log("tabvalue" + tabValue)}
      {selectedCategory === "paymentgateway" && (
        <Box display="flex" flexDirection="column" sx={{
            border: `1px solid ${theme.palette.button.faded.main}`,
            pt: "10px",
            pl: "18px",
            pr: "18px",
            pb: "15px",
            mb: "30px",
            mt: '10px',
            ml: '9px',
            background: "rgba(255,255,255,0.1)"
          }}>
          <Box mb={1}>
            <Typography fontWeight="bold" color="white">
              {t("walletcard.depositpage.quickpay.method")}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {depositDetails?.paymentGateways && Object.keys(depositDetails?.paymentGateways)?.map((method) => (
              <Grid item xs={6} sm={3}>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    borderColor:
                      selectedQuickpay ===
                      depositDetails?.paymentGateways[method]?.paymentGateway
                        ? theme.palette.primary.main
                        : theme.palette.button.faded.main,
                    display: "flex",
                    height: 50,
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    p: 1,
                    backgroundColor: darken(
                      theme.palette.background.paper,
                      0.1
                    ),
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    setSelectedQuickpay(
                      depositDetails?.paymentGateways[method]?.paymentGateway
                    );
                    setAllQuickpayBank(
                      depositDetails?.paymentGateways[method]?.banks
                    );
                    setSelectedQuickpayBank(
                      depositDetails?.paymentGateways[method]?.banks[0]
                    );
                  }}
                >
                  <Image
                    width="40px"
                    height="40px"
                    src={
                      depositDetails?.paymentGateways[method]
                        ?.paymentGatewayIcon
                    }
                  />
                  <Typography ml={mobileView && 1} color="white">
                    {depositDetails?.paymentGateways[method]?.paymentGateway}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box my={1}>
            <Typography fontWeight="bold" color="white">
              {t("walletcard.depositpage.quickpay.bank")}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {allQuickpayBank?.map((quickpayBank) => (
              <Grid item xs={6} sm={3}>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    borderColor:
                      selectedQuickpayBank === quickpayBank
                        ? theme.palette.primary.main
                        : darken(theme.palette.background.paper, 0.2),
                    display: "flex",
                    height: 50,
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    p: 1,
                    backgroundColor: darken(
                      theme.palette.background.paper,
                      0.1
                    ),
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedQuickpayBank(quickpayBank);
                    setAmount(0);
                    setReamount(0);
                  }}
                >
                  <Image
                    width="40px"
                    height="40px"
                    src={quickpayBank?.iconUrl}
                  />
                  <Typography ml={mobileView && 1} color="white">
                    {quickpayBank?.bankName}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box mt={1}>
            <Typography fontWeight="bold" color="white">
              {t("walletcard.depositpage.quickpay.depositamount")}
            </Typography>

            <form onSubmit={handleQpSubmit(submitQuickpay)}>
              <Grid item container justifyContent="space-between" spacing={1}>
                <Grid item>
                  <Typography variant="subtitle1" color="white" fontSize="14px">{t("Deposit Quota")}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" color="#FDB813" fontSize="12px">{t("KRW 30.00 - KRW 5,000.00")}</Typography>
                </Grid>
              </Grid>
              <FormTextField
                  label={`${t("walletcard.depositpage.quickpay.amount")} (${
                      process.env.REACT_APP_CURRENCY_CODE
                  })`}
                  control={qpControl}
                  controllerName="amount"
                  defaultValue={""}
                  placeholder={t("placeholder.minamount")}
                  size="small"
                  onChange={handleAmountChange}

              />
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography variant="subtitle1" color="white" fontSize="12px">{t("walletcard.depositpage.processingfee")} : </Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight="bold" color="white" align="right" fontSize="12px">
                    {process.env.REACT_APP_CURRENCY_CODE } {selectedQuickpayBank?.processingFee || '0.00'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography variant="subtitle1" color="white" fontSize="12px">{t("walletcard.depositpage.receivableamount")} : </Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight="bold" color="white" align="right" fontSize="12px">
                    {process.env.REACT_APP_CURRENCY_CODE } {reamount|| '0.00'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container py={2}>
                {depositDetails?.defaultAmountSelection?.map((item, index) => (
                  <Grid item>
                    <Button
                      key={index}
                      disableRipple
                      sx={{
                        "&.MuiButtonBase-root:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        setQpValue("amount", item.content, {
                          shouldValidate: true,
                        });
                        // Update reamount when clicking on the button
                        const processingFee = selectedQuickpayBank?.processingFee || 0;
                        const reamountValue = parseFloat(item.content) + parseFloat(processingFee);
                        setReamount(isNaN(reamountValue) ? '' : reamountValue.toFixed(2));
                      }}
                    >
                      <Box
                        display="flex"
                        sx={{
                          border: 1,
                          width: "50px",
                          height: "30px",
                          borderColor: "white",
                          boxShadow: 3,
                          borderRadius: "5px",
                        }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "12px",
                          }}
                        >
                          {item.content}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <Box display="flex" >
                <Button color="success" variant="contained" type="submit">
                  {t("walletcard.depositpage.quickpay.deposit")}
                </Button>
                <Box ml={2}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => resetQp()}
                  >
                    {t("walletcard.depositpage.quickpay.clear")}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      )}
      {/* </TabPanel> */}
      {/* <TabPanel value={tabValue} index={0}> */}
      {selectedCategory === "bank" && (
        <Box sx ={{            
          mt: '10px',
          ml: '4px',
        }}>
        <Box pl="5px" mb={1}>
          <Typography fontWeight="bold" color="white">
            {t("walletcard.depositpage.title")}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Grid container spacing={2}>
            {bankDetails?.map((item, index) => (
              <Grid item key={index} xs={4} sm={2}>
                <Box
                    sx={{
                      cursor: "pointer",
                      border: selectedBank?.id === item?.id ? 2 : null,
                      borderRadius: 1,
                      borderColor:
                          selectedBank?.id === item?.id
                              ? theme.palette.primary.main
                              : null,
                    }}
                    onClick={() => {
                      setSelectedBank(item);
                      setAmount(0);
                      setReamount(0);
                  /*    const processingFee = (selectedBank?.processingFee || depositDetails?.banks[0]?.processingFee) || 0;
                      const reamountValue = parseFloat(processingFee);
                      setReamount(isNaN(reamountValue) ? '' : reamountValue.toFixed(2));*/
                    }}
                >
                  <Image src={item?.thumbnailUrl} />
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" pl={1} mt={1} flexDirection="column">
            <Typography fontWeight="bold" color={theme.palette.primary.main}>
              {t("walletcard.depositpage.bankingdetails")}
            </Typography>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: "5px",
                "& .MuiTextField-root": {
                  pb: "30px",
                },
                border: `1px solid ${theme.palette.button.faded.main}`,
                pt: "25px",
                pl: "18px",
                pr: "18px",
                mb: "5px",
                background: "rgba(255,255,255,0.1)"
              }}
            >
              <TextField
                label={t("walletcard.depositpage.bankaccountname")}
                value={
                  selectedBank?.bankAccountName ||
                  bankDetails[0]?.bankAccountName
                }
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CopiedTooltip
                          text={
                            selectedBank?.bankAccountName ||
                            bankDetails[0]?.bankAccountName
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={t("walletcard.depositpage.bankaccountnumber")}
                value={
                  selectedBank?.bankAccountNumber ||
                  bankDetails[0]?.bankAccountNumber
                }
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CopiedTooltip
                          text={
                            selectedBank?.bankAccountNumber ||
                            bankDetails[0]?.bankAccountNumber
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={t("walletcard.depositpage.bankreference")}
                value={
                  selectedBank?.bankReference ||
                  bankDetails[0]?.bankReference
                }
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CopiedTooltip
                          text={
                            selectedBank?.bankReference ||
                            bankDetails[0]?.bankReference
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Typography fontWeight="bold" color={theme.palette.primary.main} mb="5px">
              {t("walletcard.depositpage.depositdetails")}
            </Typography>
            <Box sx={{
              border: `1px solid ${theme.palette.button.faded.main}`,
              pt: "10px",
              pl: "18px",
              pr: "18px",
              mb: "30px",
              background: "rgba(255,255,255,0.1)"
            }}>
              <Grid item container justifyContent="space-between" spacing={1}>
                <Grid item>
                  <Typography variant="subtitle1" color="white" fontSize="14px">{t("Deposit Quota")}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" color="#FDB813" fontSize="12px">{t("KRW 30.00 - KRW 5,000.00")}</Typography>
                </Grid>
              </Grid>
              <form onSubmit={handleSubmit(submitDeposit)}>
                <FormTextField
                  label={`${t("walletcard.depositpage.amount")} (${
                    process.env.REACT_APP_CURRENCY_CODE
                  })`}
                  control={control}
                  controllerName="amount"
                  defaultValue={""}
                  placeholder={t("placeholder.minamount")}
                  size="small"
                  onChange={handleAmountChange}
                />
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="subtitle1" color="white" fontSize="12px">{t("walletcard.depositpage.processingfee")} : </Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontWeight="bold" color="white" align="right" fontSize="12px">
                      {process.env.REACT_APP_CURRENCY_CODE } {selectedBank?.processingFee ||  bankDetails[0]?.processingFee || '0.00'}

                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="subtitle1" color="white" fontSize="12px">{t("walletcard.depositpage.receivableamount")} : </Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontWeight="bold" color="white" align="right" fontSize="12px">
                      {process.env.REACT_APP_CURRENCY_CODE } {reamount || '0.00'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={3}>
                  {depositDetails?.defaultAmountSelection?.map((item, index) => (
                    <Grid item>
                      <Button
                        key={index}
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          setValue("amount", item.content, {
                            shouldValidate: true,
                          });
                          const processingFee = (selectedBank?.processingFee ||  bankDetails[0]?.processingFee) || 0;
                          const reamountValue = parseFloat(item.content) + parseFloat(processingFee);
                          setReamount(isNaN(reamountValue) ? '' : reamountValue.toFixed(2));
                        }}

                      >
                        <Box
                          display="flex"
                          sx={{
                            border: 1,
                            width: "50px",
                            height: "30px",
                            borderColor: "white",
                            boxShadow: 3,
                            borderRadius: "5px",
                          }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {item.content}
                          </Typography>
                        </Box>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                {/* <FormDatePicker
                  control={control}
                  label={t("walletcard.depositpage.datetimeonreceipt")}
                  controllerName="receiptDateTime"
                  time={true}
                  size="small"
                /> */}
                <FormFileInput
                  label={t("walletcard.depositpage.attachment")}
                  control={control}
                  controllerName="receipt"
                  defaultValue={""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="file"
                  margin="dense"
                  size="small"
                />
                {/* <FormRadioButton
                title={"Promotion"}
                control={control}
                controllerName="promotion"
                items={radioButton}
              /> */}
                <Box alignSelf="center" mb={3} mt={2}>
{/*                  <Button variant="contained" type="submit">
                    {t("walletcard.submit")}
                  </Button>*/}
                  <Button
                      color="success"
                      variant="contained"
                      type="submit"
                      disabled={isDisabled}
                      //onClick={handleClick}
                      startIcon={isDisabled && <CircularProgress size={20} />}
                  >
                    {isDisabled ? `${timer}s` : 'Deposit'}
                  </Button>
                </Box>
              </form>
            </Box>
            {/*<Typography color="white">*/}
            {/*  {t("walletcard.depositpage.friendlyreminder.title")}*/}
            {/*</Typography>*/}
            {/*<Typography color="white" sx={{ textAlign: "justify" }}>*/}
            {/*  <ol>*/}
            {/*    <li style={{ margin: "10px" }}>*/}
            {/*      {t("walletcard.depositpage.friendlyreminder.firstdesc")}*/}
            {/*    </li>*/}
            {/*    <li style={{ margin: "10px" }}>*/}
            {/*      {t("walletcard.depositpage.friendlyreminder.seconddesc")}*/}
            {/*    </li>*/}
            {/*    <li style={{ margin: "10px" }}>*/}
            {/*      {t("walletcard.depositpage.friendlyreminder.thirddesc")}{" "}*/}
            {/*    </li>*/}
            {/*    <li style={{ margin: "10px" }}>*/}
            {/*      {t("walletcard.depositpage.friendlyreminder.fourthdesc")}*/}
            {/*    </li>*/}
            {/*    <li style={{ margin: "10px" }}>*/}
            {/*      {t("walletcard.depositpage.friendlyreminder.fifthdesc")}*/}
            {/*    </li>*/}
            {/*    <li style={{ margin: "10px" }}>*/}
            {/*      {t("walletcard.depositpage.friendlyreminder.sixthdesc")}*/}
            {/*    </li>*/}
            {/*    <li style={{ margin: "10px" }}>*/}
            {/*      {t("walletcard.depositpage.friendlyreminder.seventhdesc")}*/}
            {/*    </li>*/}
            {/*  </ol>*/}
            {/*</Typography>*/}
          </Box>
        </Box>
        </Box>
      )}
      {/* </TabPanel> */}
      {/* <TabPanel value={tabValue} index={1}> */}
      {selectedCategory === "ewallet" && (
        <Box sx ={{            
          mt: '10px',
          ml: '9px',
        }}>
        <Box mb={1} mt ={1}>
          <Typography fontWeight="bold" color="white">
            {t("walletcard.depositpage.title")}
          </Typography>
        </Box>
        <Grid container spacing={2} mb={1}>
            {eWalletDetails?.map((item, index) => (
              <Grid item key={index} xs={4} sm={2}>
                <Box
                    sx={{
                      cursor: "pointer",
                      border: selectedEwalletBank?.id === item?.id ? 2 : null,
                      borderRadius: 1,
                      borderColor:
                          selectedEwalletBank?.id === item?.id
                              ? theme.palette.primary.main
                              : null,
                    }}
                    onClick={() => {
                      setSelectedEwalletBank(item);
                      setAmount(0);
                      setReamount(0);
                      setSelectedQrCodeMethod('bank');
                  /*    const processingFee = (selectedBank?.processingFee || depositDetails?.banks[0]?.processingFee) || 0;
                      const reamountValue = parseFloat(processingFee);
                      setReamount(isNaN(reamountValue) ? '' : reamountValue.toFixed(2));*/
                    }}
                >
                  <Image src={item?.thumbnailUrl} />
                </Box>
              </Grid>
            ))}
          </Grid>

        
            {selectedEwalletBank?.qrCodeUrl && (
              <Box>
              <Typography fontWeight="bold" color="white" mb = {1}>
              {t("walletcard.depositpage.quickpay.method")}
            </Typography>
  <Grid container spacing={2}>
    {["bank", "qrCode"].map((method, index) => (
      <Grid item xs={4} sm={2} key={index}>
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            borderColor:
              selectedQrCodeMethod === method
                ? theme.palette.primary.main
                : theme.palette.button.faded.main,
            display: "flex",
            height: 50,
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            backgroundColor: darken(theme.palette.background.paper, 0.1),
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedQrCodeMethod(method);
          }}
        >
          {method === "bank" ? (
            <AccountBalanceIcon sx={{ color: "white", mr: 1 }} />
          ) : (
            <QrCodeIcon sx={{ color: "white", mr: 1 }} />
          )}
          <Typography color="white">
            {method === "bank"
              ? t("walletcard.depositpage.title")
              : t("walletcard.depositpage.qrCode")}
          </Typography>
        </Box>
      </Grid>
    ))}
  </Grid>
  </Box>
)}



  <Box display="flex" pl={1} mt={2} flexDirection="column" >
    {selectedQrCodeMethod === "qrCode" && selectedEwalletBank?.qrCodeUrl && (
      <Box>
      <img
        src={selectedEwalletBank.qrCodeUrl}
        alt="QR Code"
        style={{ width: "150px", height: "150px", display: "block", margin: "0 auto" }}
      />
    </Box>
    )}
  </Box>

        <Box display="flex" flexDirection="column">
        {selectedQrCodeMethod === "bank" && (
          <Box display="flex" pl={1} mt={1} flexDirection="column">
          <Typography fontWeight="bold" color={theme.palette.primary.main}>
              {t("walletcard.depositpage.bankingdetails")}
            </Typography>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: "5px",
                "& .MuiTextField-root": {
                  pb: "30px",
                },
                border: `1px solid ${theme.palette.button.faded.main}`,
                pt: "25px",
                pl: "18px",
                pr: "18px",
                mb: "5px",
                background: "rgba(255,255,255,0.1)"
              }}
            >
              <TextField
                label={t("walletcard.depositpage.bankaccountname")}
                value={
                  selectedEwalletBank?.bankAccountName ||
                  eWalletDetails[0]?.bankAccountName
                }
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CopiedTooltip
                          text={
                            selectedEwalletBank?.bankAccountName ||
                            eWalletDetails[0]?.bankAccountName
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={t("walletcard.depositpage.bankaccountnumber")}
                value={
                  selectedEwalletBank?.bankAccountNumber ||
                  eWalletDetails[0]?.bankAccountNumber
                }
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CopiedTooltip
                          text={
                            selectedEwalletBank?.bankAccountNumber ||
                            eWalletDetails[0]?.bankAccountNumber
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={t("walletcard.depositpage.bankreference")}
                value={
                  selectedEwalletBank?.bankReference ||
                  eWalletDetails[0]?.bankReference
                }
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CopiedTooltip
                          text={
                            selectedEwalletBank?.bankReference ||
                            eWalletDetails[0]?.bankReference
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            </Box>
            )}
          
          <Box display="flex" pl={1} mt={1} flexDirection="column">
          
            <Typography fontWeight="bold" color={theme.palette.primary.main} mb="5px">
              {t("walletcard.depositpage.depositdetails")}
            </Typography>
            <Box sx={{
              border: `1px solid ${theme.palette.button.faded.main}`,
              pt: "10px",
              pl: "18px",
              pr: "18px",
              mb: "30px",
              background: "rgba(255,255,255,0.1)"
            }}>
              <Grid item container justifyContent="space-between" spacing={1}>
                <Grid item>
                  <Typography variant="subtitle1" color="white" fontSize="14px">{t("Deposit Quota")}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" color="#FDB813" fontSize="12px">{t("KRW 30.00 - KRW 5,000.00")}</Typography>
                </Grid>
              </Grid>
              <form onSubmit={handleSubmit(submitQrCode)}>
                <FormTextField
                  label={`${t("walletcard.depositpage.amount")} (${
                    process.env.REACT_APP_CURRENCY_CODE
                  })`}
                  control={control}
                  controllerName="amount"
                  defaultValue={""}
                  placeholder={t("placeholder.minamount")}
                  size="small"
                  onChange={handleAmountChange}
                />
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="subtitle1" color="white" fontSize="12px">{t("walletcard.depositpage.processingfee")} : </Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontWeight="bold" color="white" align="right" fontSize="12px">
                      {process.env.REACT_APP_CURRENCY_CODE } {selectedEwalletBank?.processingFee ||  eWalletDetails[0]?.processingFee || '0.00'}

                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="subtitle1" color="white" fontSize="12px">{t("walletcard.depositpage.receivableamount")} : </Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontWeight="bold" color="white" align="right" fontSize="12px">
                      {process.env.REACT_APP_CURRENCY_CODE } {reamount || '0.00'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={3}>
                  {depositDetails?.defaultAmountSelection?.map((item, index) => (
                    <Grid item>
                      <Button
                        key={index}
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          setValue("amount", item.content, {
                            shouldValidate: true,
                          });
                          const processingFee = (selectedEwalletBank?.processingFee ||  eWalletDetails[0]?.processingFee) || 0;
                          const reamountValue = parseFloat(item.content) + parseFloat(processingFee);
                          setReamount(isNaN(reamountValue) ? '' : reamountValue.toFixed(2));
                        }}

                      >
                        <Box
                          display="flex"
                          sx={{
                            border: 1,
                            width: "50px",
                            height: "30px",
                            borderColor: "white",
                            boxShadow: 3,
                            borderRadius: "5px",
                          }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {item.content}
                          </Typography>
                        </Box>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                {/* <FormDatePicker
                  control={control}
                  label={t("walletcard.depositpage.datetimeonreceipt")}
                  controllerName="receiptDateTime"
                  time={true}
                  size="small"
                /> */}
                <FormFileInput
                  label={t("walletcard.depositpage.attachment")}
                  control={control}
                  controllerName="receipt"
                  defaultValue={""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="file"
                  margin="dense"
                  size="small"
                />
                {/* <FormRadioButton
                title={"Promotion"}
                control={control}
                controllerName="promotion"
                items={radioButton}
              /> */}
                <Box alignSelf="center" mb={3} mt={2}>
{/*                  <Button variant="contained" type="submit">
                    {t("walletcard.submit")}
                  </Button>*/}
                  <Button
                      color="success"
                      variant="contained"
                      type="submit"
                      disabled={isDisabled}
                      //onClick={handleClick}
                      startIcon={isDisabled && <CircularProgress size={20} />}
                  >
                    {isDisabled ? `${timer}s` : 'Deposit'}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
        </Box>
      // {/* </TabPanel> */}
)};
    </Box>
  );
};

export default Deposit;
