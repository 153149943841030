import React, { useState, useRef, useEffect }  from "react";
import { Phone } from "@mui/icons-material";
import { Box, Typography, useTheme, Grid } from "@mui/material";
import Image from "./Image";
import { useGetCommonBackgroundQuery } from "../../features/api/publicApiSlice";

const SideNav = () => {
  const theme = useTheme();
  const sidenavRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);

  const {
    data: commonBackground,
    isLoading: isCommonBackgroundLoading,
    isSuccess: isCommonBackgroundSuccess,
  } = useGetCommonBackgroundQuery({ prefetch: true });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidenavRef.current && !sidenavRef.current.contains(event.target)) {
        setIsOpen(false);
        setHasExpanded(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => setHasExpanded(true), 300); // Match transition duration (0.3s)
      return () => clearTimeout(timer);
    }
    setHasExpanded(false);
  }, [isOpen]);

  return (
    <>
    {/* Backdrop */}
    {isOpen && (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
          zIndex: 98,
          pointerEvents: isOpen ? "auto" : "none",
        }}
        onClick={() => {
           setIsOpen(false)
           setHasExpanded(false)
          }}
      />
    )}

    <Box
      component="div"
      ref={sidenavRef}
      sx={{
        position: "fixed",
        zIndex: "99",
        top: "40%",
        right: "0",
        transition: "width 0.3s",
        height: "120px",
        width: isOpen ? "240px" : "30px",
        boxShadow: "1px 1px #00000020",
        color: "black",
        background: `linear-gradient(to top, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        borderRadius: "6px 0 0 6px",
        cursor: "pointer",
      }}
      onClick={() => setIsOpen(true)}
    >
      <Box
        component="div"
        sx={{
          position: "fixed",
          textAlign: "center",
          width: "130px",
          paddingTop: "90px",
          transform: "rotate(90deg)"
        }}
      >
        <Typography sx={{ ml: "11px", mt:"5px", fontSize:"12px", color: "white" }}>
          <Phone sx={{ verticalAlign: "middle", mr: "5px", color: "white", width:"20px", height:"20px" }} />
          Contact Us
        </Typography>
      </Box>
      {isOpen && hasExpanded &&(
      <Box
        component="div"
        sx={{
          position: "relative",
          padding: "5px",
          display: "inline-block",
          width: "200px",
          // height: "320px",
          bgcolor: theme.palette.background.paper,
          boxShadow: "1px 1px #00000020",
          marginLeft: "40px",
        }}
      >
        {/* content goes here */}
        {!isCommonBackgroundLoading && isCommonBackgroundSuccess && (
          <Grid container spacing={0.5} pl={1}>
            {Object.keys(commonBackground?.result?.contactUs)?.map((contact) => (
              <Grid item xs={12} sx={{cursor:"pointer", marginTop: "8px",marginBottom: "8px"}} onClick={() => window.open(commonBackground?.result?.contactUs[contact]?.url, "_blank")}>
                <Box display="flex" alignItems="center">
                  <Image
                    height="30px"
                    width="30px"
                    src={commonBackground?.result?.contactUs[contact]?.icon}
                    sx={{float:"left"}}
                  />
                  <Box sx={{float:"right"}}>
                    <Typography pl={1} color="white" sx={{fontSize: "14px", fontWeight: "bold"}}>
                      {commonBackground?.result?.contactUs[contact]?.name}
                    </Typography>
                    <Typography pl={1} color="white" sx={{fontSize: "12px"}}>
                      {commonBackground?.result?.contactUs[contact]?.value}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      )}
    </Box>
    </>
  );
};

export default SideNav;
