import { Box, Button, Grid, LinearProgress, Popover, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Image from "../components/common/Image";
import { openModal } from "../features/slices/modalSlice";
import { useLazyGetBonusListPromoCenterQuery, useLazyGetBonusDetailsQuery, useClaimBonusMutation } from "../features/api/bonusApiSlice";
import DOMPurify from "dompurify";
import * as Constant from "../features/constant";
import InformationIcon from "../icons/header/InformationIcon";
import { useTranslation } from "react-i18next";

const PromoCenter = () => {
    const PATH = process.env.PUBLIC_URL;
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const [isNotProfileWallet, setisNotProfileWallet] = useState(
        location?.state?.isNotProfileWallet || false
    );

    const buttonList = [{ code: "ALL", title: t("promocenter.all") }, { code: t("promocenter.inprogress"), title: t("promocenter.inprogress") }, { code: t("promocenter.eligible"), title: t("promocenter.eligible") }, { code: t("promocenter.noteligible"), title: t("promocenter.noteligible") }]
    const [selectedButton, setSelectedButton] = useState("ALL");
    const statusList = [{ code: t("promocenter.inprogress"), desc: t("promocenter.inprogress"), image: PATH + "/assets/img/progress.svg", color: "#0093FF" }, { code: t("promocenter.eligible"), desc: t("promocenter.eligibletojoin"), image: PATH + "/assets/img/present.svg", color: "#00D600" }, { code: t("promocenter.noteligible"), desc: t("promocenter.noteligibletojoin"), image: PATH + "/assets/img/present.svg", color: "#FF0B0B" }]

    const [isProgressLength, setIsProgressLength] = useState(0);
    const [isEligibleLength, setIsEligibleLength] = useState(0);
    const [isNotEligibleLength, setIsNotEligibleLength] = useState(0);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const redirectMobile = useRef(null);

    const [isDisabled, setIsDisabled] = useState(false);

    const handleHover = (event, index) => {
        setAnchorEl({ element: event.currentTarget, index });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [
        getBonusListPromoCenter,
        {
            data: bonusList,
        },
    ] = useLazyGetBonusListPromoCenterQuery({ prefetch: true });

    const [
        getBonusDetails
    ] = useLazyGetBonusDetailsQuery({ prefetch: true });

    const [
        claimBonus
    ] = useClaimBonusMutation({ prefetch: true });

    useEffect(() => {
        getBonusListPromoCenter("ALL");
    }, [])

    useEffect(() => {
        let progressCount = 0;
        let eligibleCount = 0;
        let notEligibleCount = 0;

        bonusList?.result?.bonus.forEach(bonus => {
            if (bonus.claimable && (bonus.bonusTypeDisplay || bonus.bonusType === 'DEPOSIT' || bonus.bonusType === 'DEPOSIT_WELCOME' || bonus.bonusType === 'CUSTOM' || bonus.bonusType === 'REFERRAL' || bonus.bonusType === 'RESCUE' || bonus.bonusType === 'RESCUE_ESPORT')) {
                switch (bonus.eligibleStatus) {
                    case t("promocenter.inprogress"):
                        progressCount++;
                        break;
                    case t("promocenter.eligible"):
                        eligibleCount++;
                        break;
                    case t("promocenter.noteligible"):
                        notEligibleCount++;
                        break;
                    default:
                }
            }
        });

        setIsProgressLength(progressCount);
        setIsEligibleLength(eligibleCount);
        setIsNotEligibleLength(notEligibleCount);
    }, [bonusList]);

    const getStatusLength = (code) => {
        switch (code) {
            case t("promocenter.inprogress"):
                return isProgressLength;
            case t("promocenter.eligible"):
                return isEligibleLength;
            case t("promocenter.noteligible"):
                return isNotEligibleLength;
            default:
                return 0;
        }
    };

    const renderNoRecordMessage = (code) => {
        switch (code) {
            case t("promocenter.inprogress"):
                return isProgressLength === 0;
            case t("promocenter.eligible"):
                return isEligibleLength === 0;
            case t("promocenter.noteligible"):
                return isNotEligibleLength === 0;
            default:
                return false;
        }
    };

    return (
        <Box>
            <Box ref={redirectMobile} sx={{ marginTop: isNotProfileWallet ? 10 : undefined, marginBottom: isNotProfileWallet ? 15 : undefined, color: "white", paddingX: { xs: 1.5, sm: 3 } }}>
                <Typography fontWeight="bold" color="white">
                    {t("walletcard.promocenter")}
                </Typography>
                <Box mt={2}>
                    <Grid container display="flex" alignItems="center">
                        <Grid
                            item
                            container
                            spacing={1}
                            sx={{
                                display: "flex",
                                paddingRight: { xs: 0, sm: 4 },
                                fontSize: { xs: 12, sm: 14 },
                            }}
                        >
                            {statusList.map((item) => (
                                <Grid item key={item.desc}>
                                    <Box
                                        sx={{
                                            padding: { xs: "5px 10px", sm: "10px 15px" },
                                            borderRadius: 1,
                                            border: "solid white 1px",
                                            textTransform: "none",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            flexDirection: "row",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: 25,
                                                height: 25,
                                                backgroundColor: item.color,
                                                borderRadius: 1,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Image
                                                width={15}
                                                height={15}
                                                src={item.image}
                                            />
                                        </Box>
                                        {item.desc}
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid xs={12} sm={1} mt={3.5}>
                            <Typography>{t("walletcard.walletpage.status")}</Typography>
                        </Grid>
                        <Grid xs={11} item container spacing={1.5} mt={{ xs: 0, sm: 2 }}>
                            {buttonList.map((item) => {
                                const isSelected = selectedButton === item.code;
                                return (
                                    <Grid xs={mobileView ? 6 : 0} item key={item.code}>
                                        <Button
                                            sx={{
                                                padding: { xs: undefined, sm: "10px 20px" },
                                                width: { xs: "100%", sm: "auto" },
                                                color: isSelected ? "#FDB813" : undefined,
                                                border: "solid 1px",
                                                borderColor: isSelected ? "#FDB813" : "transparent",
                                                backgroundColor: isSelected ? undefined : "#65666A",
                                                textTransform: "none",
                                                "&:hover": {
                                                    backgroundColor: "#65666A",
                                                }
                                            }}
                                            onClick={() => {
                                                setSelectedButton(item.code);
                                            }}
                                        >
                                            {item.title}
                                        </Button>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={5}>
                    {statusList.map((item) => (
                        (selectedButton === item.code || selectedButton === "ALL") && (
                            <Box>
                                <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>{item.desc} ({getStatusLength(item.code)})</Typography>

                                {renderNoRecordMessage(item.code) && (
                                    <Typography sx={{ fontWeight: "bold", textAlign: "center", fontSize: 13 }}>
                                        {t("promocenter.norecord")}
                                    </Typography>
                                )}

                                <Grid container spacing={1.1} my={1.5}>
                                    {bonusList && bonusList.result.bonus.map((bonus, index) => (
                                        (bonus.eligibleStatus === item.code && bonus.claimable === true && (bonus.bonusTypeDisplay || bonus.bonusType === 'DEPOSIT' || bonus.bonusType === 'DEPOSIT_WELCOME' || bonus.bonusType === 'CUSTOM' || bonus.bonusType === 'REFERRAL' || bonus.bonusType === 'RESCUE' || bonus.bonusType === 'RESCUE_ESPORT')) && (
                                            <Grid xs={6} sm={4} item>
                                                <Box
                                                    pb={1}
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                        height: "100%",
                                                        backgroundColor: "#65666A",
                                                        borderRadius: 2,
                                                        px: { xs: 1, sm: 2 },
                                                    }}
                                                >
                                                    <Box>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            justifyContent="space-between"
                                                        >
                                                            <Typography
                                                                mt={1}
                                                                sx={{
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    fontSize: { xs: 11, sm: 13 },
                                                                }}
                                                            >
                                                                {bonus.displayTitle}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    minWidth: { xs: 25, sm: 38 },
                                                                    height: { xs: 30, sm: 33 },
                                                                    backgroundColor: item.color,
                                                                    borderBottomLeftRadius: 6,
                                                                    borderBottomRightRadius: 6,
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <Image
                                                                    width={mobileView ? 15 : 20}
                                                                    height={mobileView ? 15 : 20}
                                                                    src={item.image}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Typography mt={1} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 } }}>
                                                            {bonus.displayTitle}
                                                        </Typography>
                                                    </Box>

                                                    {bonus.eligibleStatus === t("promocenter.inprogress") && (
                                                        <Box
                                                            mt={2}>
                                                            <LinearProgress
                                                                variant="buffer"
                                                                value={((bonus.validTurnover ? bonus.validTurnover : 0) / (bonus.turnoverAmount ? bonus.turnoverAmount : 0)) * 100}
                                                                valueBuffer={100}
                                                                color="info"
                                                                sx={{
                                                                    borderRadius: 5,
                                                                    height: 7,
                                                                }}>
                                                            </LinearProgress>


                                                            <Typography
                                                                sx={{
                                                                    fontSize: { xs: 11, sm: 13 }
                                                                }}>
                                                                {(bonus.validTurnover ? bonus.validTurnover : 0).toFixed(2)}/{(bonus.turnoverAmount ? bonus.turnoverAmount : 0).toFixed(2)}{" "}{t("promocenter.turnover")}
                                                            </Typography>

                                                        </Box>
                                                    )}

                                                    <Box
                                                        mt={2}
                                                        display="flex"
                                                        flexDirection="row"
                                                        justifyContent="space-between"
                                                    >
                                                        <Button
                                                            sx={{
                                                                fontWeight: "bold",
                                                                fontSize: 11,
                                                                borderRadius: 20,
                                                                padding: "1px 5px",
                                                                background: "linear-gradient(180deg, hsla(213, 73%, 39%, 1) 21%, hsla(212, 94%, 33%, 1) 58%)",
                                                                textTransform: "none",
                                                            }}
                                                            onClick={() => {
                                                                getBonusDetails(bonus.bonusCode).then((data) => {
                                                                    const bonusDetails = data.data.result;
                                                                    if (data) {
                                                                        dispatch(
                                                                            openModal({
                                                                                title:
                                                                                    bonusDetails?.displayTitle,
                                                                                content: (
                                                                                    <>
                                                                                        <Image
                                                                                            src={
                                                                                                (bonusDetails?.bonusTypeThumbnailUrl || bonusDetails?.thumbnailUrl)
                                                                                            }
                                                                                            sx={{
                                                                                                width: "80%",
                                                                                            }}
                                                                                            alt={Constant.ALT_TAG_BONUS_IMG}
                                                                                        />
                                                                                        <div
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: DOMPurify.sanitize(

                                                                                                    bonusDetails?.content
                                                                                                ),
                                                                                            }}
                                                                                        ></div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        ).catch((error) => {
                                                                            console.error("Error fetching bonus details:", error);
                                                                        });
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            {t("promocenter.info")}
                                                        </Button>
                                                        <Box display={"flex"} flexDirection={"row"}>
                                                            {bonus.eligibleStatus === t("promocenter.eligible") && (
                                                                <Button
                                                                    sx={{
                                                                        fontWeight: "bold",
                                                                        fontSize: 9,
                                                                        borderRadius: 20,
                                                                        padding: "2px 8px",
                                                                        background: "linear-gradient(180deg, hsla(352, 82%, 54%, 1) 15%, hsla(352, 56%, 47%, 1) 82%)",
                                                                        textTransform: "none",
                                                                    }}
                                                                    disabled={isDisabled}

                                                                    onClick={async () => {
                                                                        setIsDisabled(true);

                                                                        let data = {
                                                                            bonusCode: bonus.bonusCode,
                                                                        };
                                                                        await claimBonus(data).then(() => {
                                                                            setIsDisabled(false);

                                                                            window.location.reload();
                                                                        });
                                                                    }}
                                                                >
                                                                    {t("promocenter.claimnow")}
                                                                </Button>
                                                            )}

                                                            {bonus.eligibleStatus === t("promocenter.noteligible") && (
                                                                <>
                                                                    <Popover
                                                                        id={id}
                                                                        sx={{
                                                                            pointerEvents: 'none',
                                                                            '& .MuiPaper-root': {
                                                                                backgroundColor: '#333333',
                                                                                color: '#ffffff',
                                                                                padding: 2,
                                                                                borderRadius: 2,
                                                                                boxShadow: 'none',
                                                                            }
                                                                        }}
                                                                        open={open && anchorEl.index === index}
                                                                        anchorEl={anchorEl ? anchorEl.element : null}
                                                                        anchorOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        disableRestoreFocus
                                                                    >
                                                                        <Typography sx={{ p: 1, fontSize: 14 }}>{bonus.eligibleCondition}</Typography>
                                                                    </Popover>
                                                                    <InformationIcon
                                                                        width={mobileView ? 15 : 20}
                                                                        height={mobileView ? 15 : 20}
                                                                        color={"#9c9a9e"}
                                                                        onMouseEnter={(event) => { handleHover(event, index) }}
                                                                        onMouseLeave={handleClose}
                                                                    />
                                                                </>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )
                                    ))}
                                </Grid>
                            </Box>
                        )
                    ))}
                </Box>
            </Box>
        </Box >
    );
}

export default PromoCenter;